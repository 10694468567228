import { useEffect, useState } from 'react';

export const useDebouncedValueChange = <T>(value: T, interval: number) => {
  const [t, setT] = useState<NodeJS.Timeout | undefined>();
  const [title, setTitle] = useState<T>(value)
  useEffect(() => {
    if (t) {
      clearTimeout(t);
    }
    setT(setTimeout(() => {
      setTitle(value);
    }, interval));

  }, [value]);
  return title;
}