import { auth } from '../App';
import { RequestError } from './request-error';

export default function createAuthRequest<T>(path: string, method: 'GET' | 'POST' | 'PUT' | 'DELETE'):
  (pathParam?: string, queryParams?: string[][], body?: any) => Promise<T | undefined> {
  return async (pathParam?: string, queryParams?: string[][], body?: any): Promise<T | undefined> => {
    const token = await auth.currentUser?.getIdToken();
    if (!token) {
      return undefined;
    }

    let url = `${process.env.REACT_APP_BACKEND_URL}/${path}`;

    if (!!pathParam) {
      url += `/${pathParam}`;
    }

    queryParams?.forEach((param, index) => {
      const delimiter = index === 0 ? '?' : '&';
      url += `${delimiter}${param[0]}=${param[1]}`;
    });

    const contentType: string | undefined = body instanceof FormData ? undefined : 'application/json';
    const formattedBody = body instanceof FormData ? body : JSON.stringify(body);
    const headers: [string, string][] = [['Authorization', `Bearer ${token}`]];
    if (!!contentType) {
      headers.push(['Content-Type', contentType]);
    }

    const response = await fetch(url, { method, body: formattedBody, headers });
    const responseBody = await response.json();

    if (response.status >= 400) {
      throw new RequestError(response.status, responseBody.error);
    }
    return responseBody;
  }
}