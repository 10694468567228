import { FormikProvider, useFormik } from 'formik';
import { Listing, ListingUpdateData, Platform, VendoraListing } from './models/listing';
import { isLocalImage, RemoteImage } from './models/image';
import { convertAttributesForVendora } from './models/category-specific-attribute';
import { Button, TextField, InputAdornment, Box, Typography, Stack, Link, Tooltip } from '@mui/material';
import { AttributesForm } from './AttributesForm';
import { CategorySelector } from './CategorySelector';
import { ImageInput } from './ImageInput';
import createAuthRequest from './utils/createAuthRequest';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { getVendoraInitialFormValues, VendoraFormFields } from './models/main-form-fields';
import { not } from './utils/functions';
import { GenericObjectSchema, vendoraValidationSchema } from './utils/validation-schemas';
import { useSnackbar } from './utils/error-toast-provider';
import { UploadFileRounded, Done } from '@mui/icons-material';
import { useEffect, useState } from 'react';
import { FormSubmitButton } from './FormSubmitButton';
import { LoadingWrapper } from './components/LoadingWrapper';
import { useCurrentSeller } from './contexts/sellerContext';

type Props = {
  existingListing?: Listing;
  listingUpdated: (updatedListing: Listing | undefined) => void;
};

export const VendoraListingForm = ({ existingListing, listingUpdated }: Props) => {
  const vendoraListing = existingListing?.crossListings?.vendora as VendoraListing | undefined;
  const formValues: VendoraFormFields = {
    ...getVendoraInitialFormValues(vendoraListing),
    images: existingListing?.images ?? []
  };

  const { showSnackbar } = useSnackbar();

  const [validationSchema, setValidationSchema] = useState(vendoraValidationSchema);
  const [attributesValidationSchemaApplied, setAttributesValidationSchemaApplied] = useState(false);

  const addAttributesToValidationSchema = (attributesSchema: GenericObjectSchema) => {
    setValidationSchema((vendoraValidationSchema as any).concat(attributesSchema));
  };

  useEffect(() => {
    if ((validationSchema as GenericObjectSchema).fields.attributes) {
      setAttributesValidationSchemaApplied(true);
    }
  }, [validationSchema]);

  const formik = useFormik({
    initialValues: formValues,
    onSubmit: (values) => updateListingMutation.mutate(values),
    validationSchema,
    validateOnMount: true
  });

  const currentSeller = useCurrentSeller();

  const prepareRequestBody = ({ title, price, description, category }: VendoraFormFields): ListingUpdateData | undefined => {
    if (!category || !existingListing) {
      return;
    }

    return {
      ...existingListing,
      crossListings: {
        ...(existingListing?.crossListings ?? {}),
        vendora: {
          ...vendoraListing,
          title,
          description,
          price,
          category,
          attributes: convertAttributesForVendora(formik.values.attributes),
          images: existingListing?.images.filter(not(isLocalImage)) ?? [] as RemoteImage[],
        } as VendoraListing
      }
    }
  };

  const updateListing = async (formValues: VendoraFormFields): Promise<Listing | undefined> => {
    try {
      const listing = await createAuthRequest<Listing>('listings', 'PUT')
        (existingListing?._id, undefined, prepareRequestBody(formValues));
      if (!listing) {
        return;
      }
      return listing;
    } catch (e) {
      return;
    }
  }

  const exportToVendora = async (): Promise<Listing | undefined> => {
    return updateListingMutation.mutateAsync(formik.values).then(async (savedListing) => {
      if (!!savedListing) {
        const listing = await createAuthRequest<Listing>(`marketplaces/vendora/listings/${existingListing?._id}`, 'PUT')();
        if (!listing) {
          console.error('Failed to export to Vendora');
          return;
        }
        return listing;
      }
    });
  }

  const updateListingMutation = useMutation({
    mutationFn: updateListing,
    onSuccess: listing => {
      if (!!listing) {
        listingUpdated(listing);
        showSnackbar('Обявата е актуализирана успешно', 'success');
      }
    },
  });


  const exportToVendoraMutation = useMutation({
    mutationFn: exportToVendora,
    onSuccess: response => {
      if (!!response) {
        listingUpdated(response);
        showSnackbar('Обявата се подготвя за качване във Vendora', 'success');
      }
    }
  });

  const vendoraExportButton = () => {
    return !!existingListing?.crossListings?.vendora?.markedForExport ?
      <Tooltip title={'Тази обява е свързана с Vendora. Ако желаеш да я премахнеш, трябва да го направиш през сайта на Vendora.'}>
        <Stack sx={{ flex: 1, justifyContent: 'center' }}>
          <Button variant='outlined' color='success'
            disabled={false}>
            <Done></Done>
            Експортирана
          </Button>
        </Stack>
      </Tooltip> :
      <Button
        name='exportToVendora'
        variant='contained'
        color='primary'
        sx={{ flex: 1 }}
        disabled={updateListingMutation.isError
          || !formik.isValid || !['connected', 'pending'].includes(currentSeller?.externalIds?.[Platform.Vendora]?.status ?? '')}
        onClick={(): void => { exportToVendoraMutation.mutate() }}
      >
        {<>
          <UploadFileRounded sx={{ lineHeight: '16px', height: '20px' }}></UploadFileRounded>
          <Typography>Експорт към Vendora</Typography>
        </>
        }
      </Button>
  }
  const vendoraUrlButton = () => {
    return !!vendoraListing?.url ?
      <Button
        variant='contained'>
        <Link color='inherit' target="_blank" href={(vendoraListing.url)}>
          Отвори във Vendora
        </Link>
      </Button> :
      <Tooltip title='Когато обявата е качена, ще можеш да я отвориш от тук.'>
        <Box>
          <Button variant='outlined' color='inherit' disabled={true}>
            Отвори във Vendora
          </Button>
        </Box>
      </Tooltip>
  }
  const queryClient = useQueryClient();

  const isListingBlockedForchanges = (): boolean => {

    const queryKeys = [
      ['attributes', `${formik.values.category}`],
      ['categories', 'vendora/categories']
    ];
    const isQueryPending = queryKeys.some(key => queryClient.getQueryState(key)?.status === 'pending');
    return isQueryPending
      || updateListingMutation.isPending
      || exportToVendoraMutation.isPending;
  }

  return (
    <>
      {
        !!vendoraListing?.markedForExport && vendoraUrlButton()
      }
      <FormikProvider value={formik}>
        <Stack width='95%' gap={2}>
          <ImageInput existingImages={existingListing?.images.filter(not(isLocalImage)) ?? [] as RemoteImage[]}
            imagesChanged={() => { }}
            disableEdit={true}
          />

          <TextField
            type='text'
            required
            name='title'
            variant='outlined'
            label='Заглавие'
            value={formik.values.title}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.touched.title && !!formik.errors.title}
            helperText={formik.touched.title && formik.errors.title}
          />

          <TextField
            type='textarea'
            multiline={true}
            required={true}
            minRows={10}
            maxRows={70}
            style={{ minWidth: '300px' }} // important, as otherwise we get a cryptic error
            // https://github.com/mui/base-ui/issues/167
            title='Описание'
            label='Описание'
            name='description'
            value={formik.values.description}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.touched.description && !!formik.errors.description}
            helperText={formik.touched.description && formik.errors.description}
          />
          {
            formik.values.title &&
            <CategorySelector
              listingTitle={formik.values.title}
              isEditing={!!existingListing}
              onCategorySelected={(selectedCategory) => formik.setFieldValue('category', selectedCategory.data.id)}
              preselectedCategoryId={formik.initialValues.category}
              marketplace={Platform.Vendora}
            />}

          {
            !!formik.values.category &&
            <AttributesForm
              selectedCategory={formik.values.category}
              initialValues={formik.initialValues.attributes}
              endpoint={(selectedCategory: string) => `vendora/categories/${selectedCategory}/attributes`}
              attributesValidationSchemaLoaded={addAttributesToValidationSchema}
              attributesValidationSchemaApplied={attributesValidationSchemaApplied}
            />
          }

          <Box display='flex' flexDirection='row' gap='10px'>
            <TextField
              type='number'
              label='Цена'
              title='Цена'
              name='price'
              value={formik.values.price ? parseFloat(formik.values.price?.toFixed(2)) : ''}
              InputProps={{
                endAdornment: <InputAdornment position='end'>€</InputAdornment>
              }}
              sx={{ width: '50%' }}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.price && !!formik.errors.price}
              helperText={formik.touched.price && formik.errors.price}
            />
          </Box>
          <LoadingWrapper loading={isListingBlockedForchanges()}>
            <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', width: '100%', gap: '10px' }}>
              <FormSubmitButton
                title='Запази'
                onClick={() => formik.handleSubmit()} />
              {vendoraExportButton()}
            </Box>
          </LoadingWrapper>
        </Stack>
      </FormikProvider >
    </>
  );
};
