import { useParams } from 'react-router-dom';
import { DummyFormSwitchToggle, FormSwitchToggle } from './FormSwitchToggle';
import { useEffect, useState } from 'react';
import { MainListingForm } from './MainListingForm';
import { OlxListingForm } from './OlxListingForm';
import { Listing, Platform } from './models/listing';
import { VendoraListingForm } from './VendoraListingForm';
import { Box, CircularProgress, Stack } from '@mui/material';
import { StyledMenuLink } from './components/StyledLink';
import { useCurrentSeller } from './contexts/sellerContext';
import { Download } from '@mui/icons-material';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import createAuthRequest from './utils/createAuthRequest';

export const ListingCreationRouterWrapper = () => {
  const urlParams = useParams();
  const existingListingId = urlParams.id;
  const platform = urlParams.platform;
  return <ListingCreation
    existingListingId={existingListingId}
    platformToRender={(Object.values(Platform)).find(item => item === platform)} />;
}

type Props = {
  existingListingId?: string;
  platformToRender?: Platform;
}

export const ListingCreation = ({ existingListingId, platformToRender }: Props) => {
  const fetchSingleListingQuery = useQuery({
    queryKey: ['listings', existingListingId],
    queryFn: () => createAuthRequest<Listing>(`listings/${existingListingId}`, 'GET')(),
    enabled: !!existingListingId
  });

  const queryClient = useQueryClient();

  useEffect(() => {
    setFormToRender(platformToRender ?? Platform.Main);
  }, [platformToRender]);

  const [formToRender, setFormToRender] = useState<Platform>(platformToRender ?? Platform.Main);

  const currentSeller = useCurrentSeller();

  const importSection = () => currentSeller?.platformCredentials[Platform.Olx] && <>
    <Box sx={{ display: 'flex', width: '100%', alignItems: 'center', justifyContent: 'start' }}>
      <StyledMenuLink to='/listings/import' sx={{ paddingX: 2, color: 'inherit' }}>
        <Box sx={{ display: 'flex', alignItems: 'center', padding: '0px' }}>
          <Download></Download>
          Добави обявите си от OLX
        </Box>
      </StyledMenuLink>
    </Box>
  </>;

  const setExistingListing = (updatedListing?: Listing) => {
    if (!updatedListing) {
      return;
    }
    queryClient.setQueryData(['listings', existingListingId], updatedListing);
  }

  const existingListing = fetchSingleListingQuery.data;

  if (existingListingId && !existingListing) {
    return <CircularProgress />;
  }

  return (
    <Stack direction={{ xs: 'column', sm: 'row' }} width={{ xs: '95%', sm: '75%' }} spacing={{ xs: 1, sm: 10 }} marginLeft={{ xs: 0, sm: 5 }}>
      {existingListing ?
        <FormSwitchToggle
          listing={{ ...existingListing }}
          onLoadForm={setFormToRender}
          listingUpdated={setExistingListing}
          openedForm={formToRender} />
        : <DummyFormSwitchToggle />
      }
      <Stack direction='column' alignItems={{ xs: 'center', sm: 'start' }} flex={5}>
        <Stack display={{ xs: 'none', sm: 'flex' }}>
          {importSection()}
        </Stack>
        {
          formToRender === Platform.Main
            ? <MainListingForm existingListing={existingListing} listingUpdated={setExistingListing} />
            : formToRender === Platform.Vendora && !!existingListing?.crossListings?.vendora
              ? <VendoraListingForm existingListing={existingListing} listingUpdated={setExistingListing} />
              : formToRender === Platform.Olx && !!existingListing?.crossListings?.olx
                ? <OlxListingForm existingListing={existingListing} listingUpdated={setExistingListing} />
                : <></>
        }
      </Stack>
    </Stack>
  );
}