import { ListItem, Typography, Box, Stack, Button, Skeleton, useMediaQuery, useTheme, IconButton, Grid } from '@mui/material';
import ImageNotSupportedIcon from '@mui/icons-material/ImageNotSupported';
import { useNavigate } from 'react-router-dom';
import { Listing, Platform } from '../models/listing';
import { useEffect, useState } from 'react';
import { RemoteImage } from '../models/image';
import { ItemCrossListingStatus } from './CrossListingStatus';
import { Delete } from '@mui/icons-material';
import { DeleteListingDialog } from '../DeleteListingDialog';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import createAuthRequest from '../utils/createAuthRequest';
import { useSnackbar } from '../utils/error-toast-provider';

export default function ListingItemSummary({ listing }: { listing: Listing }) {
  const [titleImage, setTitleImage] = useState<RemoteImage | undefined>();
  const hasImages = listing.images.length;

  const { showSnackbar } = useSnackbar();

  const navigate = useNavigate();

  useEffect(() => {
    if (listing.images.length === 0) {
      return;
    }
    setTitleImage(listing.images.reduce(
      (prev, current) => (prev.order < current.order) ? prev : current,
      listing.images[0]));
  }, [listing.images]);

  const queryClient = useQueryClient();

  const updatePage = (data: { pages: { data: Listing[] }[] }, updateFn: (data: { data: Listing[] }, index: number) => void) => {
    if (!data) {
      return data;
    }
    const pageContainingItem = data.pages.find((page) => page.data.find((oldListing) => oldListing._id === listing._id));
    if (!pageContainingItem) {
      return data;
    }
    const index = pageContainingItem.data.findIndex((oldListing) => oldListing._id === listing._id);
    if (index === -1) {
      return data;
    }
    updateFn(pageContainingItem, index);
  }

  const removeDeletedItemFromQueries = async () => {
    await queryClient.setQueryData(['listings/count', []], (oldData: number) => oldData - 1);
    await queryClient.setQueryData(['listings', []], (oldData: { pages: { data: Listing[] }[] } | undefined) =>
      updatePage(oldData ?? { pages: [] }, (pageContainingItem, index) => pageContainingItem.data.splice(index, 1))
    );
  };

  const updateListingInQuery = async (updatedListing: Listing) => {
    await queryClient.setQueryData(['listings', []], (oldData: { pages: { data: Listing[] }[] } | undefined) =>
      updatePage(oldData ?? { pages: [] }, (pageContainingItem, index) => pageContainingItem.data[index] = updatedListing)
    );
  };

  const deleteMutation = useMutation({
    mutationFn: (deletionParameters: { listingId: string, deleteInPlatforms: string }) =>
      createAuthRequest<{ listing: Listing | undefined }>(`listings/${deletionParameters.listingId}`, 'DELETE')
        (undefined, [['deleteInPlatforms', `${deletionParameters.deleteInPlatforms}`]]),
    onError: () => {
      showSnackbar('Възникна грешка при премахването на обявите.', 'error');
    },
    onSuccess: async (response: { listing: Listing | undefined } | undefined) => {
      showSnackbar('Обявите бяха актуализирани.', 'success');
      if (!response?.listing) {
        return removeDeletedItemFromQueries();
      }
      await updateListingInQuery(response.listing);
    }
  });

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const [deleteListingDialogOpen, setDeleteListingDialogOpen] = useState(false);

  const handleDeleteListing = (deletionParameters?: { listing: Listing, platforms: Platform[] }) => {
    setDeleteListingDialogOpen(false);
    if (!deletionParameters) {
      return;
    }
    return deleteMutation.mutate({
      listingId: deletionParameters.listing._id,
      deleteInPlatforms: deletionParameters.platforms.join(',')
    });
  }

  const imageWidth = isMobile ? 200 : 190;
  const actionWidth = isMobile ? 150 : 200;

  return isMobile ? (
    <Stack direction='column' alignItems='center' justifyContent='center' spacing={1}>
      <Box
        component="img"
        src={titleImage?.url}
        alt="Item"
        sx={{
          width: `${imageWidth}px`,
          maxHeight: 200,
          objectFit: 'contain',
          borderRadius: 1,
          flexShrink: 0,
          marginRight: theme.spacing(1),
        }}
      />
      <Box sx={{ textAlign: 'center' }}>
        <Typography variant='h6'>
          {listing.title}
        </Typography>
        <Typography variant="subtitle2" color="textSecondary" noWrap>
          {listing.price}лв
        </Typography>
      </Box>

      <Stack
        direction='row'
        sx={{
          width: `${actionWidth}px`,
          flexShrink: 0,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'flex-end',
        }}
      >
        <Button variant='contained' onClick={() => navigate(`/listings/${listing._id}/edit/main`)} size="small">
          Виж повече
        </Button>
        <IconButton onClick={() => setDeleteListingDialogOpen(true)} aria-label="delete" size="small">
          <Delete color='error' fontSize="small" />
        </IconButton>
      </Stack>
    </Stack >
  ) : (
    <ListItem
      sx={{
        height: '200px',
        padding: 0,
        borderBottom: 1, borderColor: 'grey.500'
      }}
    >
      <Grid container alignItems="center" justifyItems='center' wrap="nowrap">
        <Grid item sx={{ width: `${imageWidth}px`, maxHeight: 200, flexShrink: 0 }}>
          {!hasImages && <ImageNotSupportedIcon />}
          <Box
            component="img"
            src={titleImage?.url}
            alt="Item"
            sx={{
              width: '100%',
              maxHeight: 170,
              objectFit: 'contain',
            }}
          />
        </Grid>

        <Grid
          item
          sx={{
            flexGrow: 1,
            paddingLeft: theme.spacing(2),
            overflow: 'hidden',
          }}
        >
          <Typography variant="h5">
            {listing.title}
          </Typography>
          <Typography variant="subtitle2" color="textSecondary" noWrap>
            {listing.price}лв
          </Typography>
        </Grid>

        <Grid
          item
          sx={{
            paddingRight: theme.spacing(5)
          }}
        >
          {!isMobile && <ItemCrossListingStatus listing={listing} />}
        </Grid>

        <Grid
          item
          sx={{
            width: `${actionWidth}px`,
            flexShrink: 0,
            display: 'flex',
            alignItems: 'center',
            justifyContent: isMobile ? 'flex-end' : 'flex-end',
            flexDirection: isMobile ? 'column' : 'row',
          }}
        >
          <Button
            variant='contained'
            onClick={() => navigate(`/listings/${listing._id}/edit/main`)}
            size={isMobile ? 'small' : 'medium'}
            sx={{ marginBottom: isMobile ? theme.spacing(0.5) : 0, marginRight: isMobile ? 0 : theme.spacing(1) }}
          >
            Виж повече
          </Button>
          <IconButton onClick={() => setDeleteListingDialogOpen(true)} aria-label="delete" size={isMobile ? 'small' : 'medium'}>
            <Delete color='error' />
          </IconButton>
        </Grid>
      </Grid>
      <DeleteListingDialog
        listing={listing}
        onClose={handleDeleteListing}
        open={deleteListingDialogOpen} />
    </ListItem>
  );
};

export const ListingItemSummarySkeleton = () => {
  return (
    <Stack padding={1} width='100%' direction={{ xs: 'column', sm: 'row' }} justifyContent='space-between' alignItems='center'>
      <Skeleton variant='rectangular' width={150} height={150} />
      <Stack direction={'column'}>
        <Skeleton variant='text' width={200} height={80} />
        <Skeleton variant='text' width={100} height={40} />
      </Stack>
      <Skeleton variant='rectangular' width={300} height={40} />
      <Skeleton variant='rectangular' width={120} height={40} />
    </Stack>
  );
}