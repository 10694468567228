import { FormikProvider, useFormik } from 'formik';
import { Listing, ListingUpdateData, OlxListing, OlxStatus, Platform } from './models/listing';
import { isLocalImage, RemoteImage } from './models/image';
import { convertAttributesForSubmission } from './models/category-specific-attribute';
import { useDebouncedValueChange } from './hooks/useDebouncedValueChange';
import { Button, TextField, InputAdornment, Link, Stack, Box } from '@mui/material';
import { AttributesForm } from './AttributesForm';
import { CategorySelector } from './CategorySelector';
import { ImageInput } from './ImageInput';
import { LocationSelector } from './LocationSelector';
import createAuthRequest from './utils/createAuthRequest';
import { useMutation } from '@tanstack/react-query';
import { FormFields, getInitialFormValues } from './models/main-form-fields';
import { not } from './utils/functions';
import { GenericObjectSchema, mainFormValidationSchema } from './utils/validation-schemas';
import { useSnackbar } from './utils/error-toast-provider';
import { useEffect, useState } from 'react';
import { FormSubmitButton } from './FormSubmitButton';
import { LoadingWrapper } from './components/LoadingWrapper';
import { useQueryClient } from '@tanstack/react-query';
import { useCurrentSeller } from './contexts/sellerContext';

type Props = {
  existingListing: Listing;
  listingUpdated: (updatedListing: Listing | undefined) => void;
};

export const OlxListingForm = ({ existingListing, listingUpdated }: Props) => {
  const olxListing = existingListing?.crossListings?.olx;

  const { showSnackbar } = useSnackbar();

  const initialValues: FormFields = {
    ...getInitialFormValues(olxListing),
    images: existingListing?.images ?? []
  };

  const [validationSchema, setValidationSchema] = useState(mainFormValidationSchema);
  const [attributesValidationSchemaApplied, setAttributesValidationSchemaApplied] = useState(false);

  const addAttributesToValidationSchema = (attributesSchema: GenericObjectSchema) => {
    setValidationSchema((mainFormValidationSchema as any).concat(attributesSchema));
  };

  useEffect(() => {
    if ((validationSchema as GenericObjectSchema).fields.attributes) {
      setAttributesValidationSchemaApplied(true);
    }
  }, [validationSchema]);

  const formik = useFormik({
    initialValues,
    onSubmit: (values) => updateListingMutation.mutate(values),
    validationSchema,
    validateOnMount: true
  });

  const currentSeller = useCurrentSeller();

  const prepareRequestBody = ({ title, price, description, category, city, district }: FormFields): ListingUpdateData | undefined => {
    if (!category || !city) {
      return;
    }

    return {
      ...existingListing,
      crossListings: {
        ...(existingListing.crossListings ?? {}),
        olx: {
          ...olxListing,
          title,
          description,
          price,
          category,
          location: {
            cityId: city,
            districtId: district
          },
          attributes: convertAttributesForSubmission(formik.values.attributes),
          images: formik.values.images.filter(not(isLocalImage)) as RemoteImage[],
          contact: {
            name: 'Peshoooo'
          }
        }
      }
    }
  };


  const updateListing = async (formValues: FormFields): Promise<Listing | undefined> => {
    try {
      const listing = await createAuthRequest<Listing>('listings', 'PUT')
        (existingListing?._id, undefined, prepareRequestBody(formValues));
      if (!listing) {
        return;
      }
      return listing;
    } catch (e) {
      console.error(e);
      return;
    }
  }

  const exportToOlx = async (): Promise<Listing | undefined> => {
    return updateListingMutation.mutateAsync(formik.values).then(async (savedListing) => {
      if (!!savedListing) {
        const listing = await createAuthRequest<Listing>(`marketplaces/olx/listings/${existingListing?._id}`, 'PUT')();
        if (!listing || !listing.externalIdentifiers?.olx) {
          console.error('Failed to export to OLX');
          return;
        }
        return listing;
      }
    });
  }

  const exportToOlxMutation = useMutation({
    mutationFn: exportToOlx,
    onSuccess: response => {
      if (!!response) {
        showSnackbar('Обявата беше успешно качена в OLX', 'success');
        listingUpdated(response);
      }
    },
    onError: () => {
      showSnackbar('Неуспешно качване в OLX', 'error');
    }
  });

  const updateListingMutation = useMutation({
    mutationFn: updateListing,
    onSuccess: listing => {
      if (!!listing) {
        showSnackbar('Обявата е актуализирана успешно', 'success');
        listingUpdated(listing);
      }
    },
  });

  const title = useDebouncedValueChange(formik.values.title, 200);

  const queryClient = useQueryClient();
  const isListingBlockedForchanges = (): boolean => {

    const queryKeys = [
      ['categories', 'taxonomy/categories'],
      ['cities', 'taxonomy/cities'],
      ['districts', `taxonomy/cities/${formik.values.city}/districts`],
      ['attributes', formik.values.category],
      ['suggestions', title]
    ];

    const isQueryPending = queryKeys.some(query => queryClient.getQueryState(query)?.status === 'pending');
    return isQueryPending
      || updateListingMutation.isPending
      || exportToOlxMutation.isPending;
  }

  if (!olxListing) {
    return <></>;
  }

  return <>
    {
      !!olxListing.url && <Button
        variant='contained'>
        <Link color='inherit' target="_blank" href={(existingListing?.crossListings?.olx as OlxListing)?.url}>
          Отвори в OLX
        </Link>
      </Button>
    }
    <FormikProvider value={formik}>
      <Stack width='95%' gap={2}>
        <ImageInput existingImages={existingListing.images.filter(not(isLocalImage)) as RemoteImage[]}
          imagesChanged={() => { }}
          disableEdit={true}
        />

        <TextField
          type='text'
          required
          name='title'
          variant='outlined'
          label='Заглавие'
          rows={Math.ceil(formik.values.title.length / 40)}
          value={formik.values.title}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={formik.touched.title && !!formik.errors.title}
          helperText={formik.touched.title && formik.errors.title}
        />

        <TextField
          type='textarea'
          multiline={true}
          required={true}
          minRows={10}
          maxRows={70}
          style={{ minWidth: '300px' }} // important, as otherwise we get a cryptic error
          // https://github.com/mui/base-ui/issues/167
          title='Описание'
          label='Описание'
          name='description'
          value={formik.values.description}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={formik.touched.description && !!formik.errors.description}
          helperText={formik.touched.description && formik.errors.description}
        />
        {
          title &&
          <CategorySelector
            listingTitle={title}
            isEditing={!!existingListing}
            onCategorySelected={(selectedCategory) => formik.setFieldValue('category', selectedCategory.data.id)}
            preselectedCategoryId={formik.initialValues.category}
            suggestionsEndpoint='taxonomy/categories/suggestions'
            marketplace={Platform.Olx}
          />}

        {
          !!formik.values.category &&
          <AttributesForm
            selectedCategory={formik.values.category}
            initialValues={formik.initialValues.attributes}
            endpoint={(selectedCategory: string) => `taxonomy/categories/${selectedCategory}/attributes`}
            attributesValidationSchemaLoaded={addAttributesToValidationSchema}
            attributesValidationSchemaApplied={attributesValidationSchemaApplied}
          />
        }

        <Stack direction={{ xs: 'column', sm: 'row' }} justifyContent='space-evenly' alignItems={{ xs: 'center' }} spacing={{ xs: 2, sm: 2 }}>
          <LocationSelector
            endpoint='taxonomy/cities'
            formField='city'
            locationTypeLabel='Град'
            preselectedLocationId={formik.initialValues.city}
          />

          {formik.values.city && <LocationSelector
            endpoint={`taxonomy/cities/${formik.values.city}/districts`}
            formField='district'
            locationTypeLabel='Квартал'
            preselectedLocationId={formik.initialValues.district}
          />}

          <TextField
            type='number'
            label='Цена'
            title='Цена'
            name='price'
            value={formik.values.price}
            InputProps={{
              endAdornment: <InputAdornment position='end'>лв</InputAdornment>
            }}
            sx={{ flex: 1 }}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.touched.price && !!formik.errors.price}
            helperText={formik.touched.price && formik.errors.price}
          />
        </Stack>
        <LoadingWrapper loading={isListingBlockedForchanges()}>
          <Box sx={{ width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'center', gap: '10px' }}>
            <FormSubmitButton
              title='Запази'
              onClick={() => formik.handleSubmit()} />
            <Button
              name='exportToOlx'
              variant='contained'
              color='primary'
              disabled={!formik.isValid || !currentSeller?.platformCredentials[Platform.Olx]}
              onClick={(): void => { exportToOlxMutation.mutate() }}
              sx={{ flex: 1 }}
            >
              {!!existingListing?.externalIdentifiers?.olx
                ? olxListing.status === OlxStatus.RemovedByUser ? 'Активирай в OLX' : 'Редактирай в OLX'
                : 'Експорт към OLX'}
            </Button>
          </Box>
        </LoadingWrapper>
      </Stack>
    </FormikProvider >
  </>
};
